import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import {
  employeeDeleteReducer,
  employeeListReducer,
  employeeCreateReducer,
  employeeDetailsReducer,
  employeeUpdateReducer,
  employeeAllListReducer,
  employeeDeactivateReducer,
  employeeByDepartmentListReducer,
} from "./reducers/employeeReducers";
import {
  updatePasswordReducer,
  userLoginReducer,
  userWithRoleListReducer,
} from "./reducers/userReducers";
import {
  productListReducer,
  categoryListReducer,
  mappedProductListReducer,
  productCreateReducer,
  productDetailsReducer,
  productUpdateReducer,
  productDeleteReducer,
  productPriceByIdListReducer,
  productPriceCreateReducer,
  productPriceListReducer,
  productMapReducer,
  //Product Funciton
  
  productFunctionListReducer,
  productFunctionCreateReducer,
  productFunctionDeleteReducer,
  productFunctionDetailsReducer,
  productFunctionUpdateReducer,

  courntryOriginListReducer,
  brandListReducer,
  groupListReducer,
  CategoryBasedProductDetailsReducer,
  priceDetailsReducer,
  mappedProductsReducer,
  productSampleUpdateReducer,
} from "./reducers/productReducers";
import {
  assignedCustomerListReducer,
  customerCreateReducer,
  customerDeleteReducer,
  customerDetailsReducer,
  customerListReducer,
  customerMapListReducer,
  customerUpdateReducer,
  unAssignedCustomerListReducer,
  updateAssignedCustomerReducer,
} from "./reducers/customerReducers";
import {
  roleCreateReducer,
  RoleListDetailsReducer,
  RoleListReducer,
  updateAssignedMenuReducer,
  roleForSpecificUserUpdateReducer,
} from "./reducers/RoleAndMenuReducers";
import {
  activityCreateReducer,
  activityDeleteReducer,
  activityDupliListReducer,
  activityListReducer,
  activityUpdateReducer,
  doneActivityDetailsReducer,
  DoneActivityListReducer,
} from "./reducers/MarketingActivityReducers";
import {
  channelListReducer,
  departmentListReducer,
  designationListReducer,
  employeesByChannelListReducer,
} from "./reducers/channelReducers";
import {
  invoiceOrderTypeListReducer,
  invoiceProductListReducer,
  invoiceProductListReducerDupli,
  listSalesOrderInternalReducer,
  SalesOrderInternalCreateReducer,
  salesOrderInternalDetailsReducer,
  TallySalesCreateReducer,
  listBatchAndMappedItemBasedOnProductReducer,
  SalesOrderInternalPackageReducer,
  SalesOrderInternalUpdateReducer,
  SalesOrderInternalEscalateReducer,
  SalesOrderInternalPackageDoneReducer,
  salesOrderInternalPackageDoneDetailsReducer,
  SalesOrderInternalPackageDoneUpdateReducer,
  listPriceSummaryBasedOnProductAndCustomerReducer,
  invoicePackTypeListReducer,
  invoiceOrderFromListReducer,
  listSalesOrderInternalFilterReducer,
  pendingListReducer,
  ListJobSearchSingleReducer,
  StockoutPendingUpdateReducer,
  listSalesOrderInternalPendingPriceReducer,

  //sticker
  listOrderPackingAndEditReducer,
  packingDetailsEditReducer,
  packingStickerCreateReducer,
  packingStickerUpdateReducer,

  latestBatchAndMapDetailsReducer,
  
} from "./reducers/invoiceReducers";
import {
  supplierCreateReducer,
  supplierDeleteReducer,
  supplierDetailsReducer,
  supplierDropdownListReducer,
  supplierListReducer,
  supplierUpdateReducer,
} from "./reducers/supplierReducers";
import {
  productPurchasePriceCreateReducer,
  productPurchasePriceDetailsReducer,
  productPurchasePriceUpdateReducer,
  productSellingPriceCreateReducer,
  productSellingPriceDetailsReducer,
  productSellingPriceUpdateReducer,
  purchasePriceHistoryDetailsReducer,
  purchasePriceListReducer,
  sellingPriceHistoryDetailsReducer,
  sellingPriceListReducer,
} from "./reducers/priceReducers";
import {
  batchDetailsReducer,
  batchListReducer,
  batchUpdateReducer,
} from "./reducers/BatchReducers";
import {
  StockinAdjustReducer,
  StockinCreateReducer,
  StockinDetailsReducer,
  StockinEscalateReducer,
  StockinQtyUpdateReducer,
  StockinUpdateReducer,
  listStockinReducer,
  listStockinInvoiceReducer,
  detailsStockinDataByIdeReducer
} from "./reducers/StockinReducers";
import {
  QuotatioDetailsReducer,
  QuotationApproveUpdateReducer,
  QuotationApprovedListReducer,
  QuotationCreateReducer,
  QuotationListReducer,
  QuotationUpdateReducer,
} from "./reducers/quotationReducers";
import {
  BankListReducer,
  ChequeCreateReducer,
  ChequeDeleteReducer,
  ChequeDetailsReducer,
  ChequeListReducer,
  ChequeStatusListReducer,
  ChequeTypeListReducer,
  ChequeUpdateReducer,
  IssuerBankListReducer,
} from "./reducers/chequeReducers";
import {
  InvoiceChequeListReducer,
  InvoiceDetailsReducer,
  PaymentCreateReducer,
  PaymentDetailsReducer,
  PaymentHistoryDetailsReducer,
  PaymentListReducer,
  PaymentModeListReducer,
  PaymentUpdateReducer,
} from "./reducers/paymentReducers";
import {
  CheckListCompleteReducer,
  EmployeeTaskListReducer,
  ListTaskPendingChecklistDetailsesreReducer,
  LocationListReducer,
  TaskCheckListReducer,
  TaskCheckListReducerDupli,
  TaskCreateReducer,
  TaskDetailsReducer,
  TaskEndReducer,
  TaskIncompleteListReducer,
  TaskJobTypeListReducer,
  TaskNameReducer,
  TaskStartReducer,
  TaskUnAssignedListReducer,
  TaskUpdateReducer,
  TransportCreateReducer,
  TransportDeleteReducer,
  TransportEndLocationCreateReducer,
  TransportUpdateReducer,
  VehicleListReducer,
  taskEndReducer,
  taskStartReducer,
} from "./reducers/taskReducers";
import {
  getChannelsListReducer,
  getCurrentStockAdjustmentReducer,
  getCurrentStockReducer,
  getProductsListReducer,
  getReferenceListReducer,
  getCustomersListReducer,
} from "./reducers/CurrentStockReportReducers";
import { pendingAndCancelReportReducer } from "./reducers/pendingAndCancelReportReducers";
import { dailyInvoiceTrackingReducer } from "./reducers/dailyInvoiceTrackingReducers";
import { allowanceCheckingReducer } from "./reducers/allowanceCheckingReducer";

import {
  locationListReducer,
  locationCreateReducer,
  locationDetailsReducer,
  locationUpdateReducer,
  locationDeleteReducer,
  locationDropdownListReducer,
} from "./reducers/locationReducers";

import {
  dashboardTodaysSaleInfoReducer,
  dashboardCurrMonthSaleInfoReducer,
  dashboardCalenderSalesInfoReducer,
  dashboardCustomerSalesInfoReducer,
  dashboardProductSalesInfoReducer,
} from "./reducers/dashboardReducers";


import{
barcodeCreateReducer,
listPrintBarcodeInfoReducer,
barcodeRemoveByParamsReducer,
packingListBarcodeMapReducer,
barcodeDecodeReducer,
insertSOBarcodeReducer,
updateStockOutBarcodeReducer,
removeStockOutBarcodeReducer,
repackingBarcodeCreateReducer,
repackingListBarcodeReducer,
repackingRemoveBarcodeReducer,
} from "./reducers/BarcodeReducer"

// import { getProductsListReducer } from "./reducers/CurrentStockReportReducers";

// Create all reducers instances
const reducer = combineReducers({
  employeeList: employeeListReducer,
  employeeCreate: employeeCreateReducer,
  employeeDelete: employeeDeleteReducer,
  employeeDeactivate: employeeDeactivateReducer,
  employeeDetails: employeeDetailsReducer,
  employeeUpdate: employeeUpdateReducer,
  employeeAllList: employeeAllListReducer,
  employeeByDepartmentList: employeeByDepartmentListReducer,

  productList: productListReducer,
  mappedProductList: mappedProductListReducer,
  categoryList: categoryListReducer,
  //Product Function
  
  functionList: productFunctionListReducer,
  createProductFuctionState:productFunctionCreateReducer,
  listProductFuctionDetailsState:productFunctionDetailsReducer,
  updateProductFuctionState:productFunctionUpdateReducer,
  deleteProductFuctionState:productFunctionDeleteReducer,

  countryOriginList: courntryOriginListReducer,
  brandList: brandListReducer,
  groupList: groupListReducer,
  productDetails: productDetailsReducer,
  productCreate: productCreateReducer,
  productDelete: productDeleteReducer,
  productUpdate: productUpdateReducer,
  productMap: productMapReducer,
  mappedProducts: mappedProductsReducer,
  productSampleUpdate: productSampleUpdateReducer,
  categoryBasedProductDetails: CategoryBasedProductDetailsReducer,

  customerList: customerListReducer,
  // productNameList:getProductsListReducer,
  customerMapList: customerMapListReducer,
  customerCreate: customerCreateReducer,
  customerDelete: customerDeleteReducer,
  customerDetails: customerDetailsReducer,
  customerUpdate: customerUpdateReducer,
  unAssignedCustomerList: unAssignedCustomerListReducer,
  assignedCustomerList: assignedCustomerListReducer,
  updateAssignedCustomer: updateAssignedCustomerReducer,

  productPriceById: productPriceByIdListReducer,
  productPriceCreate: productPriceCreateReducer,
  productPriceList: productPriceListReducer,
  priceDetails: priceDetailsReducer,

  roleList: RoleListReducer,
  roleDetails: RoleListDetailsReducer,
  updateAssignedMenu: updateAssignedMenuReducer,
  createdRole: roleCreateReducer,
  updateUserRole: roleForSpecificUserUpdateReducer,

  activityList: activityListReducer,
  activityDupliList: activityDupliListReducer,
  doneActivityList: DoneActivityListReducer,
  activityCreate: activityCreateReducer,
  activityDelete: activityDeleteReducer,
  activityDetails: doneActivityDetailsReducer,
  activityUpdate: activityUpdateReducer,

  channelList: channelListReducer,
  employeesByChannelList: employeesByChannelListReducer,
  designationList: designationListReducer,
  departmentList: departmentListReducer,

  invoiceProductList: invoiceProductListReducer,
  invoiceProductListDupli: invoiceProductListReducerDupli,
  invoicePackTypeList: invoicePackTypeListReducer,
  invoiceOrderFromList: invoiceOrderFromListReducer,

  invoiceOrderTypeList: invoiceOrderTypeListReducer,
  salesOrderInternalList: listSalesOrderInternalReducer,
  listSalesOrderInternalFilter: listSalesOrderInternalFilterReducer,
  salesOrderInternalCreate: SalesOrderInternalCreateReducer,
  salesOrderInternalUpdate: SalesOrderInternalUpdateReducer,
  salesOrderInternalPackage: SalesOrderInternalPackageReducer,
  salesOrderInternalPackageDone: SalesOrderInternalPackageDoneReducer,
  salesOrderInternalEscalate: SalesOrderInternalEscalateReducer,
  SalesOrderInternalPendingPriceList: listSalesOrderInternalPendingPriceReducer,

  salesOrderInternalDetails: salesOrderInternalDetailsReducer,
  salesOrderInternalPackageDoneDetails:
    salesOrderInternalPackageDoneDetailsReducer,
  batchAndMappedItemListByProductDetails:
    listBatchAndMappedItemBasedOnProductReducer,
  createdTallySales: TallySalesCreateReducer,

  salesOrderInternalPackageDoneUpdate:
    SalesOrderInternalPackageDoneUpdateReducer,

  priceSummaryBasedOnProductAndCustomerList:
    listPriceSummaryBasedOnProductAndCustomerReducer,

  supplierList: supplierListReducer,
  supplierCreate: supplierCreateReducer,
  supplierDetails: supplierDetailsReducer,
  supplierUpdate: supplierUpdateReducer,
  supplierDelete: supplierDeleteReducer,
  supplierDropdownList: supplierDropdownListReducer,

    //Report
    allowanceCheckingState:allowanceCheckingReducer,


  userWithRole: userWithRoleListReducer,
  userLogin: userLoginReducer,
  passwordUpdate: updatePasswordReducer,

  purchasePriceList: purchasePriceListReducer,
  purchasePriceHistoryDetails: purchasePriceHistoryDetailsReducer,
  productPurchasePriceCreate: productPurchasePriceCreateReducer,
  productPurchasePriceDetails: productPurchasePriceDetailsReducer,
  productPurchasePriceUpdate: productPurchasePriceUpdateReducer,

  sellingPriceList: sellingPriceListReducer,
  sellingPriceHistoryDetails: sellingPriceHistoryDetailsReducer,
  productSellingPriceCreate: productSellingPriceCreateReducer,
  productSellingPriceDetails: productSellingPriceDetailsReducer,
  productSellingPriceUpdate: productSellingPriceUpdateReducer,

  batchList: batchListReducer,
  batchDetails: batchDetailsReducer,
  batchUpdate: batchUpdateReducer,

  stockinList: listStockinReducer,
  stockinCreate: StockinCreateReducer,
  stockinAdjust: StockinAdjustReducer,
  stockinEscalate: StockinEscalateReducer,
  stockinDetails: StockinDetailsReducer,
  stockinUpdate: StockinUpdateReducer,
  stockinQtyUpdate: StockinQtyUpdateReducer,

  quotationApprovedList: QuotationApprovedListReducer,
  quotationCreate: QuotationCreateReducer,
  quotatioDetails: QuotatioDetailsReducer,
  quotationUpdate: QuotationUpdateReducer,
  quotationApproveUpdate: QuotationApproveUpdateReducer,

  chequeStatusList: ChequeStatusListReducer,
  bankList: BankListReducer,
  chequeTypeList: ChequeTypeListReducer,

  chequeList: ChequeListReducer,
  chequeCreate: ChequeCreateReducer,
  chequeDetails: ChequeDetailsReducer,
  chequeUpdate: ChequeUpdateReducer,
  chequeDelete: ChequeDeleteReducer,

  paymentList: PaymentListReducer,
  paymentModeList: PaymentModeListReducer,
  invoiceChequeList: InvoiceChequeListReducer,
  invoiceDetailsReducer: InvoiceDetailsReducer,
  paymentCreate: PaymentCreateReducer,
  paymentDetails: PaymentDetailsReducer,
  paymentUpdate: PaymentUpdateReducer,
  paymentHistoryDetails: PaymentHistoryDetailsReducer,

  taskJobTypeListReducer: TaskJobTypeListReducer,
  taskCheckList: TaskCheckListReducer,
  taskCheckListDupli: TaskCheckListReducerDupli,
  taskCreate: TaskCreateReducer,
  taskDetails: TaskDetailsReducer,
  taskPendingChecklistDetails: ListTaskPendingChecklistDetailsesreReducer,
  taskUpdate: TaskUpdateReducer,
  employeeTaskList: EmployeeTaskListReducer,
  taskIncompleteList: TaskIncompleteListReducer,

  vehicleList: VehicleListReducer,
  locationList: LocationListReducer,
  transportCreate: TransportCreateReducer,
  transportUpdate: TransportUpdateReducer,
  transportDelete: TransportDeleteReducer,
  transportEndLocationCreate: TransportEndLocationCreateReducer,
  taskStart: TaskStartReducer,
  taskEnd: TaskEndReducer,
  checkListComplete: CheckListCompleteReducer,
  taskName: TaskNameReducer,

  pendingList: pendingListReducer,
  taskUnAssignedList: TaskUnAssignedListReducer,
  issuerBankList: IssuerBankListReducer,

  listJobSearchSingle: ListJobSearchSingleReducer,
  stockoutPendingUpdate: StockoutPendingUpdateReducer,

  productNameList: getProductsListReducer,
  customerNameListState:getCustomersListReducer,
  ReferenceNameList: getReferenceListReducer,
  getChannelNameList: getChannelsListReducer,
  getCurrentStock: getCurrentStockReducer,
  getCurrentStockAdjustment: getCurrentStockAdjustmentReducer,
  pendingAndCancelReportList: pendingAndCancelReportReducer,
  dailyInvoiceTrackingReportList: dailyInvoiceTrackingReducer,
  
  //Asif start
  latestBatchAndMapDetails: latestBatchAndMapDetailsReducer,
  //Asif end



  //location
  listLocationsAction :locationListReducer,
  createLocationState:locationCreateReducer,
  listLocationDetailsState:locationDetailsReducer,
  updateLocationState:locationUpdateReducer,
  deleteLocationAction:locationDeleteReducer,
  listLocationForDropdownAction:locationDropdownListReducer,

  //Sticker
  packingDetailsState:packingDetailsEditReducer,
  listOrderForPackingAndEditState:listOrderPackingAndEditReducer,
  packingStickerCreateState:packingStickerCreateReducer,
  packingStickerUpdateState:packingStickerUpdateReducer,

  //dashboard
  dashboardTodaysSaleInfoList: dashboardTodaysSaleInfoReducer,
  dashboardCurrMonthSaleInfoList: dashboardCurrMonthSaleInfoReducer,
  dashboardCalenderSalesInfoList: dashboardCalenderSalesInfoReducer,
  dashboardCustomerSalesInfoList: dashboardCustomerSalesInfoReducer,
  dashboardProductSalesInfoList: dashboardProductSalesInfoReducer,

 //Barcode

  barcodeCreateState:barcodeCreateReducer,
  printBarcdoeListState:listPrintBarcodeInfoReducer,
  removeBarcdoeByParamsState:barcodeRemoveByParamsReducer,
  packingBarcodeMappingState:packingListBarcodeMapReducer,
  barcodeReadingState:barcodeDecodeReducer,
  insertSOBarcodeState:insertSOBarcodeReducer,
  updateSOBarcodeState:updateStockOutBarcodeReducer,
  removeSOBarcodeState:removeStockOutBarcodeReducer,
  
  repakcingBarcodeCreateState:repackingBarcodeCreateReducer,
  repackingPrintListState:repackingListBarcodeReducer,
  repackingBarcodeRemoveState:repackingRemoveBarcodeReducer,

  listStockInInvoiceState:listStockinInvoiceReducer,
  stockInDataByIdState:detailsStockinDataByIdeReducer,

});

// Get information saved in localStorage, if any exists

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

// Initial Redux state with localStorage info, if exists
const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
